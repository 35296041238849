import { createSlice } from "@reduxjs/toolkit";
import api from "../services/api";
import { toast } from "react-toastify";

export const search = createSlice({
  name: "search",
  initialState: {
    items: [],
    variants: [],
  },
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload;
    },
    setVariants: (state, action) => {
      state.variants = action.payload;
    },
  },
});

export const searchDetailsItemsByCodeOrArticle =
  ({ article, code }) =>
  async (dispatch, getState) => {
    try {
      const query = [article && `article=${article}`, code && `code=${code}`];

      const response = await api.get(
        `/api/v1/shop/load/1c?${query.join("&")}`,
        {}
      );

      if (response.status === 200) {
        const json = await response.data;
        if (json.length === 0) toast.error("Не найдено");
        dispatch(searchVariantByItem1C(json["0"].id));
        dispatch(setItems(json));
      }
    } catch (e) {
      toast.error("Не найдено");
      console.error(e);
    }
  };

export const searchVariantByItem1C = (id) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/shop/variants?item1cId=${id}`, {});

    if (response.status === 200) {
      const json = await response.data;
      if (json.length === 0) toast.error("Не найдено");
      dispatch(setVariants(json));
    }
  } catch (e) {
    toast.error("Не найдено");
    console.error(e);
  }
};

export const { setItems, setVariants } = search.actions;

export default search.reducer;
