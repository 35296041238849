import {
  Box,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  searchDetailsItemsByCodeOrArticle,
  searchVariantByItem1C,
  setItems,
} from "../../store/Search";

export default function Search() {
  const dispatch = useDispatch();

  const items = useSelector((state) => state.search.items);
  const variants = useSelector((state) => state.search.variants);

  const [searchText, setSearchText] = useState("");

  const onSearch = () => {
    dispatch(setItems([]));
    if (searchText.includes(".")) {
      dispatch(searchDetailsItemsByCodeOrArticle({ code: searchText }));
    } else {
      dispatch(searchDetailsItemsByCodeOrArticle({ article: searchText }));
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <TextField
          type="text"
          label="Поиск"
          variant="outlined"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <IconButton
          sx={{
            ml: "15px",
          }}
          onClick={onSearch}
        >
          <SearchIcon sx={{ color: "#5955b3" }} />
        </IconButton>
      </Box>
      <TableContainer>
        <Typography variant="h6" sx={{ mb: 2, mt: 5 }}>
          Детали
        </Typography>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>Название</TableCell>
              <TableCell>Кастомное название</TableCell>
              <TableCell>Артикул</TableCell>
              <TableCell>Номенклатурный номер</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.length
              ? items.map(({ id, name, custom_name, article, code }, i) => (
                  <TableRow key={id}>
                    <TableCell component="th" scope="row">
                      {i + 1}
                    </TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell>{custom_name}</TableCell>
                    <TableCell>{article}</TableCell>
                    <TableCell>{code}</TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
        <Typography variant="h6" sx={{ mb: 2, mt: 5 }}>
          Сборки
        </Typography>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>Название</TableCell>
              <TableCell>Номенклатурный номер</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {variants.length
              ? variants.map(({ id, title, fullCode }, i) => (
                  <TableRow key={id}>
                    <TableCell component="th" scope="row">
                      {i + 1}
                    </TableCell>
                    <TableCell>{title}</TableCell>
                    <TableCell>{fullCode}</TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
