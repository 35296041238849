import { configureStore } from "@reduxjs/toolkit";
import machineTypesReducer from "./MachineTypes";
import machineTypeReducer from "./MachineType";
import partsReducer from "./Parts";
import partReducer from "./Part";
import subpartReducer from "./SubPart";
import subsubpartReducer from "./SubSubPart";
import preloaderReducer from "./Preloader";
import imagerReducer from "./Imager";
import detailsReducer from "./Details";
import variantsReducer from "./Variants";
import variantReducer from "./Variant";
import variantItemsReducer from "./VariantItems";
import modelsReducer from "./Models";
import searchItemReducer from "./SearchItem";
import AuthReducer from "./Auth";
import rateReducer from "./Rate";
import modelGroupReducer from "./ModelGroup";
import mapModelsReducer from "./MapModel";
import searchReducer from "./Search";
import detail1cReducer from "./Details1C";

export default configureStore({
  reducer: {
    preloader: preloaderReducer,
    machineTypes: machineTypesReducer,
    machineType: machineTypeReducer,
    parts: partsReducer,
    part: partReducer,
    subpart: subpartReducer,
    subsubpart: subsubpartReducer,
    imager: imagerReducer,
    details: detailsReducer,
    variants: variantsReducer,
    models: modelsReducer,
    variant: variantReducer,
    variantItems: variantItemsReducer,
    searchItem: searchItemReducer,
    Auth: AuthReducer,
    rate: rateReducer,
    modelGroup: modelGroupReducer,
    mapModels: mapModelsReducer,
    search: searchReducer,
    details1c: detail1cReducer,
  },
});
