import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDetails1CById, setDetail1cById } from "../../store/Details1C";

export default function Detail() {
  const { id } = useParams();

  const dispatch = useDispatch();

  const details1cById = useSelector((state) => state.details1c.details1cById);

  useEffect(() => {
    dispatch(setDetail1cById([]));
    dispatch(getDetails1CById(id));
  }, []);

  return (
    <TableContainer>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Детали
      </Typography>
      {/* <Box sx={{ display: "flex", mb: 2, justifyContent: "end" }}>
        <TextField type="number" label="Кратность" variant="outlined" />
        <Button
          sx={{
            ml: 2,
          }}
          color="custom_purple"
          variant="contained"
        >
          Обновить
        </Button>
      </Box> */}
      <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>№</TableCell>
            <TableCell>Название</TableCell>
            <TableCell>Кастомное название</TableCell>
            <TableCell>Артикул</TableCell>
            <TableCell>Номенклатурный номер</TableCell>
            <TableCell>Сборка</TableCell>
            <TableCell>Номенклатурный номер (Сборки)</TableCell>
            <TableCell>Собственное</TableCell>
            <TableCell>Покупные</TableCell>
            <TableCell>Ходовая</TableCell>
            <TableCell>Быстроизнашиваемые</TableCell>
            <TableCell>Масса</TableCell>
            <TableCell>Цена</TableCell>
            <TableCell>Применение в моделях</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {details1cById.length
            ? details1cById.map((item, i) => (
                <TableRow key={item.id}>
                  <TableCell component="th" scope="row">
                    {i + 1}
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.custom_name}</TableCell>
                  <TableCell>{item.article}</TableCell>
                  <TableCell>{item.code}</TableCell>
                  <TableCell>{item.variant_title}</TableCell>
                  <TableCell>{item.variant_full_code}</TableCell>
                  <TableCell>
                    {item.build && (
                      <Box textAlign="center">
                        <CheckCircleIcon fontSize="small" color="success" />
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    {item.shop && (
                      <Box textAlign="center">
                        <CheckCircleIcon fontSize="small" color="success" />
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    {item.chassis && (
                      <Box textAlign="center">
                        <CheckCircleIcon fontSize="small" color="success" />
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    {item.wearable_products && (
                      <Box textAlign="center">
                        <CheckCircleIcon fontSize="small" color="success" />
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>{item.weight}</TableCell>
                  <TableCell>{item.price}</TableCell>
                  <TableCell>
                    {item.models ? item.models.join(", ") : ""}
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
