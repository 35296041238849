import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  get1cItems,
  sync1cItems,
  setItems,
  delete1Item,
} from "../../store/Rate";
import {
  Box,
  Fab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Preloaderr from "../../components/Preloaderr";
import SearchIcon from "@mui/icons-material/Search";
import Modal from "../../components/Modal";
import DialogAction from "../../components/DialogAction";
import EditRateForm from "./EditRateForm";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function Rate() {
  const dispatch = useDispatch();

  const items = useSelector((state) => state.rate.items);

  const [article, setArticle] = useState("");
  const [modal, setModal] = useState(false);
  const [modalDel, setModalDel] = useState(false);
  const [item, setItem] = useState(null);

  useEffect(() => {
    return () => {
      dispatch(setItems(null));
    };
  }, []);

  /* useEffect(() => {
        if (article.length >= 6)
            dispatch(get1cItems(article))
    }, [article]) */

  //if (items === null) return <Preloaderr />

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <TextField
          type="number"
          label="Поиск по артикулу"
          variant="outlined"
          value={article}
          onChange={(e) => setArticle(e.target.value)}
        />
        <IconButton
          sx={{
            ml: "15px",
          }}
          onClick={() => {
            dispatch(setItems(null));
            dispatch(get1cItems(article));
          }}
        >
          <SearchIcon sx={{ color: "#5955b3" }} />
        </IconButton>
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>Название</TableCell>
              <TableCell>Артикул</TableCell>
              <TableCell>Номенклатурный номер</TableCell>
              <TableCell>Кратность</TableCell>
              <TableCell align="right">&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items !== null
              ? items.map((item, i) => (
                  <TableRow key={item.id}>
                    <TableCell component="th" scope="row">
                      {i + 1}
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.article}</TableCell>
                    <TableCell>{item.code}</TableCell>
                    <TableCell>{item.rate}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        sx={{ mr: 1 }}
                        edge="end"
                        aria-label="comment"
                        onClick={() => {
                          setItem(item);
                          setModal(true);
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="comment"
                        color="error"
                        onClick={() => {
                          setItem(item);
                          setModalDel(true);
                        }}
                      >
                        <HighlightOffIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={modal} close={() => setModal(false)}>
        <EditRateForm item={item} />
      </Modal>
      <Modal open={modalDel} close={() => setModalDel(false)}>
        <DialogAction
          title="Удалить"
          text={`Вы уверены что хотите удалить запись "${
            item !== null ? item.name : ""
          }"?`}
          agree="Удалить"
          open={modalDel}
          setOpen={setModalDel}
          action={() => {
            dispatch(delete1Item(item.id, item.code));
          }}
        />
      </Modal>
    </>
  );
}

export default Rate;
