import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  Link,
  Pagination,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDetails1C, setDetail1c } from "../../store/Details1C";
import { sync1cItems } from "../../store/Rate";

export default function Details() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const queryNotLinked =
    searchParams.get("notLinked") === "true" ? true : false;
  const queryNotCatigories =
    searchParams.get("notCatigories") === "true" ? true : false;

  const [page, setPage] = useState(
    !isNaN(searchParams.get("page")) ? Number(searchParams.get("page")) : 1
  );
  const [searchText, setSearchText] = useState("");
  const [notLinked, setNotLinked] = useState(queryNotLinked);
  const [notCatigories, setNotCatigories] = useState(queryNotCatigories);

  const detail1c = useSelector((state) => state.details1c.detail1c);

  useEffect(() => {
    loadData(page);
  }, []);

  useEffect(() => {
    if (notLinked !== queryNotLinked || notCatigories !== queryNotCatigories) {
      loadData(1);
    }
  }, [notLinked, notCatigories]);

  const handlePage = (page) => {
    loadData(page);
  };

  const loadData = (page = 1) => {
    setSearchParams({
      page,
      notLinked,
      notCatigories,
    });
    console.log({
      page,
      notLinked,
      notCatigories,
    });
    setPage(page);
    dispatch(setDetail1c([]));
    if (searchText.includes(".")) {
      dispatch(
        getDetails1C({
          page: page,
          code: searchText,
          isNotLinked: notLinked,
          isNotCatigories: notCatigories,
        })
      );
    } else {
      dispatch(
        getDetails1C({
          page: page,
          article: searchText,
          isNotLinked: notLinked,
          isNotCatigories: notCatigories,
        })
      );
    }
  };

  const onSearch = () => {
    loadData();
  };

  return (
    <>
      <Box
        sx={{
          mb: 2,
        }}
      >
        <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              type="text"
              label="Поиск"
              variant="outlined"
              onChange={(e) => setSearchText(e.target.value)}
            />
            <IconButton
              sx={{
                ml: "15px",
              }}
              onClick={onSearch}
            >
              <SearchIcon sx={{ color: "#5955b3" }} />
            </IconButton>
          </Box>
          <Box sx={{ ml: 2 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={notLinked}
                    onChange={() => setNotLinked(!notLinked)}
                  />
                }
                label="Не привязана к сборке"
              />
            </FormGroup>
          </Box>
          <Box sx={{ ml: 2 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={notCatigories}
                    onChange={() => setNotCatigories(!notCatigories)}
                  />
                }
                label="Не привязана к группе"
              />
            </FormGroup>
          </Box>
          <Box
            sx={{
              ml: "auto",
            }}
          >
            <Button
              variant="contained"
              color="custom_purple"
              onClick={() => dispatch(sync1cItems())}
              endIcon={<CloudSyncIcon />}
            >
              Синхронизация с 1с
            </Button>
          </Box>
        </Box>
      </Box>
      <div>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>Название</TableCell>
              <TableCell>Кастомное название</TableCell>
              <TableCell>Артикул</TableCell>
              <TableCell>Номенклатурный номер</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {detail1c.data && detail1c.data.length
              ? detail1c.data.map(
                  ({ id, name, custom_name, article, code }, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        <Link
                          onClick={() => navigate(`/details/${id}`)}
                          style={{ cursor: "pointer" }}
                        >
                          {i + 1}
                        </Link>
                      </TableCell>
                      <TableCell>{name}</TableCell>
                      <TableCell>{custom_name}</TableCell>
                      <TableCell>{article}</TableCell>
                      <TableCell>{code}</TableCell>
                    </TableRow>
                  )
                )
              : null}
          </TableBody>
        </Table>
        <Pagination
          sx={{
            marginTop: "24px",
            marginBottom: "24px",
          }}
          count={
            typeof detail1c.last_page !== "undefined" ? detail1c.last_page : 0
          }
          page={page}
          shape="rounded"
          onChange={(event, page) => handlePage(page)}
        />
      </div>
    </>
  );
}
