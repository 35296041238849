import { createSlice } from "@reduxjs/toolkit";
import api from "../services/api";
import { toast } from "react-toastify";

export const detail1c = createSlice({
  name: "search",
  initialState: {
    detail1c: [],
    details1cById: [],
  },
  reducers: {
    setDetail1c: (state, action) => {
      state.detail1c = action.payload;
    },
    setDetail1cById: (state, action) => {
      state.details1cById = action.payload;
    },
  },
});

export const getDetails1C =
  ({ page, article, code, isNotLinked, isNotCatigories }) =>
  async (dispatch, getState) => {
    try {
      const query = [
        page && `page=${page}`,
        article && `article=${article}`,
        code && `code=${code}`,
        isNotLinked && `isNotLinked=${isNotLinked}`,
        isNotCatigories && `isNotCatigories=${isNotCatigories}`,
      ].filter(Boolean);

      const response = await api.get(
        `/api/v1/shop/details?${query.join("&")}`,
        {}
      );

      if (response.status === 200) {
        const json = await response.data;
        if (json.length === 0) toast.error("Не найдено");
        dispatch(setDetail1c(json));
      }
    } catch (e) {
      toast.error("Не найдено");
      console.error(e);
    }
  };

export const getDetails1CById = (id) => async (dispatch, getState) => {
  try {
    const response = await api.get(`/api/v1/shop/details/${id}`, {});

    if (response.status === 200) {
      const json = await response.data;
      if (json.length === 0) toast.error("Не найдено");
      dispatch(setDetail1cById(json));
    }
  } catch (e) {
    toast.error("Не найдено");
    console.error(e);
  }
};

export const { setDetail1c, setDetail1cById } = detail1c.actions;

export default detail1c.reducer;
